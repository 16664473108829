import { Component, OnInit, OnDestroy } from '@angular/core';
import { PendingApprovalService } from './pending-approval-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services';

@Component({
	selector: 'app-pending-approval',
	standalone: false,
	templateUrl: './pending-approval.component.html',
	styleUrls: ['./pending-approval.component.scss'],
})
export class PendingApprovalComponent implements OnInit, OnDestroy {
	questionnaireCompleted: boolean = false;
	onboardingScheduled: boolean = false;
	isApproved: boolean = false;
	loading: boolean = false;
	userId: string = '';
	isEULAAccepted = false;
	private statusCheckInterval: any;

	constructor(
		private approvalService: PendingApprovalService,
		private route: ActivatedRoute,
		private router: Router,
		private auth: AuthService,
	) {}

	ngOnInit() {
		this.userId = this.route.snapshot.params['userId'];
		this.fetchApprovalStatus(this.userId);
		this.statusCheckInterval = setInterval(() => {
			this.fetchApprovalStatus(this.userId);
		}, 30 *1000);
	}

	private fetchApprovalStatus(userId) {
		this.loading = true;
		this.approvalService.fetchApprovalStatus(userId).subscribe({
			next: response => {
				this.questionnaireCompleted = response.isQuestionnaireComplete;
				this.onboardingScheduled = response.isCustomerSuccessCallScheduled;
				this.isApproved = response.isClientApproved;
				this.isEULAAccepted = response.isEULAAccepted;
				if (
					this.questionnaireCompleted &&
					this.onboardingScheduled &&
					this.isApproved &&
					this.isEULAAccepted
				) {
					this.auth.getProfile().subscribe(data => {
						this.auth.setCookieOrganisationId(data.Organization_ID);
						this.router.navigate(['orders/current']);
					});
				}
			},
			error: error => {
				console.error('Error fetching approval status:', error);
				// Handle error appropriately (show error message to user)
			},
			complete: () => {
				this.loading = false;
			},
		});
	}

	completeQuestionnaire(event: Event) {
		if (!this.questionnaireCompleted) {
			// TODO: Implement local UI update or navigation to questionnaire
			console.log('Navigate to questionnaire or show questionnaire modal');
		}
	}

	scheduleOnboarding(event: Event) {
		if (!this.onboardingScheduled) {
			// TODO: Implement local UI update or navigation to scheduling
			console.log('Navigate to scheduling page or show scheduling modal');
		}
	}

	onClickGo() {
		if (
			this.questionnaireCompleted &&
			this.onboardingScheduled &&
			this.isApproved &&
			this.isEULAAccepted
		) {
			this.router.navigate(['orders/current']).then();
			return;
		} else if (
			this.questionnaireCompleted &&
			this.onboardingScheduled &&
			this.isApproved &&
			!this.isEULAAccepted
		) {
			this.router.navigate(['termsandconditions/' + this.userId]).then();
		}
	}

	ngOnDestroy(): void {
		if (this.statusCheckInterval) {
			clearInterval(this.statusCheckInterval);
		}
	}
}
