import {Component, OnInit} from '@angular/core';
import {StaticTextService, AuthService} from '../../services';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
	selector: 'app-top-navigation',
	templateUrl: './top-navigation.component.html',
	styleUrls: ['./top-navigation.component.scss'],
})
export class TopNavigationComponent implements OnInit {
	currentPath: string = '';

	constructor(
		public text: StaticTextService,
		public auth: AuthService,
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		this.router.events.subscribe((url: any) => this.currentPath = this.router.url);
	}

	checkRoute(type) {
		return this.route.routeConfig.path.includes(type);
	}

  goToLogin(e){
	e.preventDefault()
    this.auth.logout();
	this.router.navigate(['/login']).then()
  }

}
