import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService, ConfigService, IntercomService, NewOrderService, StaticTextService, AboutService} from '../../services';
import {DialogService} from '../../module/dialog/bootstrap-modal.module';
import {SuccessComponent} from '../../dialog/success/success.component';
declare var window: any;
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserNpsComponent} from '../../common/user-nps/user-nps.component';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  loginFrm: UntypedFormGroup;
  loginFrm1: UntypedFormGroup;
  error: string = '';
  errorType: string = '';
  errorMsg: any = {};
  returnUrl = '';
  loginModel: any = {};
  @ViewChild('myfocus') myfocus:any
  timer:any;
  isPassMatch: boolean= true;
  userData: any = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private frmBuilder: UntypedFormBuilder,
    private auth: AuthService,
    public text: StaticTextService,
    // private dialogService: DialogService,
    private matDialog:MatDialog,
    private newOrderService: NewOrderService,
    private config: ConfigService,
    private interCom: IntercomService,
    private aboutService: AboutService,
    private _snackBar: MatSnackBar,
    private titleService: Title,
  ) {
    this.loginFrm = frmBuilder.group({
      'Email_Address': [null, Validators.compose([Validators.required,Validators.maxLength(255)])],
        // 'User_Password': [null, Validators.compose([Validators.required, Validators.minLength(8),        
        // Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/), Validators.maxLength(255)])]
      'User_Password': [null, Validators.compose([Validators.required,Validators.maxLength(255)])]
    })
    this.loginFrm.valueChanges.subscribe(data => this.error = '');

      this.loginFrm1 = frmBuilder.group({
        'password': [null, Validators.compose([
        Validators.required,Validators.minLength(8), 
        Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/),
        Validators.maxLength(255),       
        ])],
      'confirmpassword': [null, Validators.required],
    })
    this.loginFrm1.valueChanges.subscribe(data => this.error = '');
  }

  ngOnInit() {
    this.titleService.setTitle(`Pippin Title™ - Client - Login`)
    this.auth.logout();
    this.auth.serverHealthCheck()
    .subscribe((res)=>{
      if (this.auth.isLoggedIn()) {
        this.router.navigate(['/']);
      }
      this.newOrderService.resetAll();
      //
      if (this.route.snapshot.queryParams['returnUrl']) {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
      } else this.returnUrl = '/orders/current';
      this.auth.resetEmailIdForForgetPwd();
      this.route.params.subscribe((params) => {
        let msg = 'Registration successful. Please check your email to finish setting up your account';
        if (params.success == 'success'){
          // window.HubSpotConversations.widget.refresh();
          this.successMsgDialog(this.text.get('TXT55'), this.text.get('TXT25'));
          this.router.navigate(['/login'])
          .then(() => {
            self.location.reload();
          });
        }
        else if (params.success == 'changePassword') this.successMsgDialog(this.text.get('LBL15'), this.text.get('TXT36'));
        else if (params.success == 'approved'){
          // window.HubSpotConversations.widget.refresh();
          this.successMsgDialog(this.text.get('TXT55'), msg);
          this.router.navigate(['/login'])
          .then(() => {
            self.location.reload();
          });
        }
      });
      this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
    })
   
  }

  ngOnDestroy(){
    if(this.timer)clearTimeout(this.timer)
  }

  successMsgDialog(title, msg) {

    let dialogRef=this.matDialog.open(SuccessComponent,{
      data:{
        title: title,
          message: msg,
      }
    })


    // let disposable = this.dialogService.addDialog(SuccessComponent, {
    //   title: title,
    //   message: msg,
    // }).subscribe((res) => {});
  }


  login() {
    if (!this.auth.isLoggedIn()) {
      this.router.navigate(['/login']).then();
    }
    this.auth.login(this.loginModel)
      .subscribe(
        (response) => {
			console.log({login_response:response})
          if(response && response.IsPassReqdMatch){
			const userId = response.user ? response.user.User_ID : response.User_ID
            if(response.isApprovalMissing){
              this.router.navigate([`pendingApproval/${userId}`]).then();
            }
            else if (!response || !response.token) {
              this.router.navigate(['termsandconditions/' + userId]).then();
            } else {
              window['hsConversationsSettings'] = {
                loadImmediately: false
              };
            const _hsq = window['_hsq'] = window['_hsq'] || [];
            _hsq.push(['setContentType', 'CONTENT_TYPE']);
            this.auth.showLoggedInProfile()
            this.aboutService.getUserNPSAcceptance()
              .subscribe((npsRes)=> {
                if(npsRes && !npsRes.acceptance) this.displayNpsForUser();
              }, (err) => {
                console.log("Error while geting the user's NPS accepantance", err);
              })
            if(response.isApprovalMissing){
                console.log('In here')
                this.router.navigate([`pendingApproval/${response.User_ID}`]).then();
            }
            else  if (!response || !response.token)
              this.router.navigate(['termsandconditions/' + response.User_ID]).then();
            else
              this.router.navigate([this.returnUrl]).then();
            } 
          }else{
            this.isPassMatch = false;
            this.userData['User_ID'] = response.User_ID;
          }
        },
        (error) => {this.errorMsg = error;
          console.log({error})
         },
      );
  }

  updateAndLogin(){
      this.errorMsg.msg = '';
      this.userData['Cur_Password'] = this.loginModel.password;// user new password
      this.userData['User_Password'] = this.loginModel.User_Password;// user current password
      if(this.loginFrm1.valid){
        this.auth.changeClientPassword(this.userData)
          .subscribe((res) => {
              let dialogRef=this.matDialog.open(SuccessComponent,{
                data:{
                  title: "Success",
                message: "Your password has been successfully updated!"
                }
              })

              
              // let disposable = this.dialogService.addDialog(SuccessComponent, {
              //   title: "Success",
              //   message: "Your password has been successfully updated!"
              // })
              // .subscribe((res) => {});
              this.loginModel['User_Password']=this.loginModel.password;
              this.login();
          }, (error) => {
            this.errorMsg = error;
            this.errorType = error.type;
          });
      } else {
        this.errorType = 'general';
        this.errorMsg.msg = 'Please enter correct details in all required fields';
      }
  }

  changeModelVal(modelVal) {
    this.errorMsg = {};
  }

  changeModelVal1(modelVal) {
    this.errorMsg = {};
  }
  display() {}

  goToForget(userEmail) {
    if (userEmail != undefined) {
      this.auth.setEmailIdForForgetPwd(userEmail);
    }
    this.router.navigate(['/forget']);
  }

  displayNpsForUser() {
    let snackBarRef = this._snackBar.openFromComponent(UserNpsComponent);
  }

  ngAfterViewInit() {
    this.auth.init().subscribe((res) => {
      this.auth.loadHubSpotChatToken();
    });
  }

}