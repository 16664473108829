import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
	Address,
	addressToObj,
	BillingAddress,
	capitalize,
	Dict,
	get,
	isNotEmptyString,
	to,
} from '@utils';
import { CookieService } from 'ngx-cookie';
import {
	BehaviorSubject,
	Observable,
	throwError as observableThrowError,
	Subject,
} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CONSTANTS } from '../../app.constants';
import { ConfigService } from '../config/config.service';
import { ScrollService } from '../scroll/scroll.service';
import { SocketService } from '../socket/socket.service';
import { StateService } from '../state/state.service';
declare var window: any;
import { MatSnackBar } from '@angular/material/snack-bar';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthService {
	private viewMenu: boolean;
	private forgetEmailId: any;
	private versionData: string = '';
	private userEmail: string;
	private previousUrl: any = {};
	private cookieObject: any;
	redirectURL: string = '';
	private emailAddress: any;
	private hubSpotToken: any;

	private _isMultiCompanyUser$: BehaviorSubject<any> = new BehaviorSubject(false);
	public isMultiCompanyUser$: Observable<any> = this._isMultiCompanyUser$.asObservable();
	private _isMultiCompany$: BehaviorSubject<any> = new BehaviorSubject(false);
	public isMultiCompany$: Observable<any> = this._isMultiCompany$.asObservable();
	private isMultiCompany = false;

	constructor(
		private http: HttpClient,
		private cookies: CookieService,
		private config: ConfigService,
		private socket: SocketService,
		private router: Router,
		private stateService: StateService,
		private scrollService: ScrollService,
		private _snackBar: MatSnackBar,
	) {
		this.viewMenu = false;
	}

	setMultiAccountStatus(val) {
		this.isMultiCompany = val;
		this._isMultiCompany$.next(val);
	}

	setRedirectURL(url) {
		this.redirectURL = url;
	}

	getRedirectURL() {
		return this.redirectURL;
	}

	resetRedirectURL() {
		this.redirectURL = '';
	}

	get token() {
		return get(this.cookies.getObject('pippinTitleUsr'), 'token');
	}
	getToken = (): string => {
		if (this.cookies.getObject('pippinTitleUsr'))
			return this.cookies.getObject('pippinTitleUsr')['token'];
		return null;
	};

	getPrevUrl() {
		return this.previousUrl.url;
	}

	setPrevUrl(url) {
		this.previousUrl = url;
	}

	fetchVersionDetails() {
		this.http.get('env.txt', { responseType: 'text' }).subscribe((res: any) => {
			this.setVersionDetails(res);
		});
	}

	public get isAuth(): boolean {
		return Boolean(this.token);
	}

	isLoggedIn(): boolean {
		if (this.getToken()) return true;
		else return false;
	}

	showLoggedInHdr(): boolean {
		if (window.location.href.includes('/pendingApproval')) return false;
		if (this.getToken() && !this.isMultiCompany) return true;
		else return false;
	}

	canActivate(): any {
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
			observe: 'response' as 'body',
		};

		if (this.isLoggedIn())
			return this.http
				.get(this.config.getBasePath() + '/users/isLoggedIn', httpOptions)
				.pipe(
					map(
						response => response,
						error => observableThrowError(error),
					),
				);

		return observableThrowError(false);
	}

	//
	// ───────────────────────────────────────────────────────────── REGISTRATION ─────
	//

	registerNewUser(newUser) {
		let httpOptions = {
			observe: 'response' as 'body',
		};
		if (newUser) {
			newUser.access_token = this.config.getAccessToken();
			return this.http
				.post(this.config.getBasePath() + '/users/register', newUser, httpOptions)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(error => observableThrowError(error.error)),
				);
		}
	}

	setpassword(setPassObj) {
		let httpOptions = {
			observe: 'response' as 'body',
		};
		setPassObj.access_token = this.config.getAccessToken();
		return this.http
			.post(
				this.config.getBasePath() + '/users/customer/createpassword',
				setPassObj,
				httpOptions,
			)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(error => observableThrowError(error.error)),
			);
	}

	changePassword(modelObj) {
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
			observe: 'response' as 'body',
		};
		//modelObj.token =  this.getToken();
		return this.http
			.put(this.config.getBasePath() + '/users/update/password', modelObj, httpOptions)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError((error: any) => observableThrowError(error.error)),
			);
	}

	changeClientPassword(modelObj) {
		let httpOptions = {
			observe: 'response' as 'body',
		};
		modelObj.access_token = this.config.getAccessToken();
		return this.http
			.post(
				this.config.getBasePath() + '/users/update/clientpassword',
				modelObj,
				httpOptions,
			)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError((error: any) => observableThrowError(error.error)),
			);
	}

	updateEmail(modelObj) {
		// let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
		// let options = new RequestOptions({ headers: headers });

		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
			observe: 'response' as 'body',
		};
		return this.http
			.put(this.config.getBasePath() + '/users/update/email', modelObj, httpOptions)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError((error: any) => observableThrowError(error.error)),
			);
	}

	updateCompanyName(modelObj) {
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
			observe: 'response' as 'body',
		};
		return this.http
			.put(this.config.getBasePath() + '/users/update/company', modelObj, httpOptions)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError((error: any) => observableThrowError(error.error)),
			);
	}

	/* ---------------------------------- */

	public updateAddress(modelObj) {
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
			observe: 'response' as 'body',
		};
		return this.http
			.put(this.config.getBasePath() + '/users/update/address', modelObj, httpOptions)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError((error: any) => observableThrowError(error.error)),
			);
	}

	/* ---------------------------------- */
	public getDefaultAddress = async () => {
		// Query user default billing address, return if found.
		let [err, address] = await to(this.getBillingAddress());
		if (!err && address) return address;

		// Fallback query user profile address, save as billing address if found.
		[err, address] = await to(this.getProfile());
		if (!err && address) {
			[err, address] = await to(this.createBillingAddress(address));
			return address;
		}
		return null;
	};

	public getBillingAddress = () =>
		this.http.get(`${this.config.baseUrl}/users/client/${this.userId}/billing/address`, {
			headers: new HttpHeaders({ Authorization: `Bearer ${this.token}` }),
		});

	public createBillingAddress = (address: Address | BillingAddress | Dict) =>
		this.http.post(
			`${this.config.baseUrl}/users/client/billing/address`,
			{ ...addressToObj(address, 'billing'), User_ID: this.userId },
			{ headers: new HttpHeaders({ Authorization: `Bearer ${this.token}` }) },
		);

	public updateBillingAddress = (address: Address | BillingAddress | Dict) =>
		this.http.put(
			`${this.config.baseUrl}/users/client/update/billing/address`,
			{ ...addressToObj(address, 'billing'), User_ID: this.userId },
			{ headers: new HttpHeaders({ Authorization: `Bearer ${this.token}` }) },
		);

	/* ---------------------------------- */

	updateUserInfo(modelObj) {
		modelObj.User_ID = this.getUserId();
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
			observe: 'response' as 'body',
		};
		return this.http
			.put(this.config.getBasePath() + '/users/update/info', modelObj, httpOptions)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError((error: any) => observableThrowError(error.error)),
			);
	}

	updatePhoneFax(modelObj) {
		modelObj.User_ID = this.getUserId();
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
			observe: 'response' as 'body',
		};
		return this.http
			.put(this.config.getBasePath() + '/users/update/phoneOrFax', modelObj, httpOptions)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError((error: any) => observableThrowError(error.error)),
			);
	}

	getProfile() {
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
		};
		let url = this.config.getBasePath() + '/users/profile/' + this.getUserId();
		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				// create a cookies for newuser
				//this.cookies.put('userProfile', JSON.stringify(response.json()));
				return response;
			}),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}

	setCookieFirstName(value) {
		var pippinUser = this.cookies.getObject('pippinTitleUsr');
		if (pippinUser) {
			pippinUser['user']['User_First_Name'] = value;
		}
		this.cookies.put('pippinTitleUsr', JSON.stringify(pippinUser));
	}

	setCookieLastName(value) {
		var pippinUser = this.cookies.getObject('pippinTitleUsr');
		if (pippinUser) {
			pippinUser['user']['User_Last_Name'] = value;
		}
		this.cookies.put('pippinTitleUsr', JSON.stringify(pippinUser));
	}

	setCookieCompanyName(value) {
		var pippinUser = this.cookies.getObject('pippinTitleUsr');
		if (pippinUser) {
			pippinUser['user']['User_Company_Name'] = value;
		}
		this.cookies.put('pippinTitleUsr', JSON.stringify(pippinUser));
	}

	setCookieOrganisationId(value) {
		const pippinUser = this.cookies.getObject('pippinTitleUsr');
		if (pippinUser) {
			pippinUser['user']['Organization_ID'] = value;
		}
		this.cookies.put('pippinTitleUsr', JSON.stringify(pippinUser));
	}

	//
	// ────────────────────────────────────────────────────────────────── SESSION ─────
	//

	encrypt(data: string): string {
		return CryptoJS.AES.encrypt(data, this.config.encryptionKey).toString();
	}

	login(loginData: any) {
		let username: string = loginData.Email_Address;
		let password: string = loginData.User_Password;

		let access_token: string = this.config.getAccessToken();
		const authBase64 = 'Basic ' + btoa(username + ':' + password);
		const authToken = this.encrypt(authBase64);
		let httpOptions = {
			headers: new HttpHeaders({
				Authorization: authToken,
			}),
		};

		return this.http
			.post(
				this.config.getBasePath() + '/auth/login',
				{ access_token: access_token, User_Role: 'client' },
				httpOptions,
			)
			.pipe(
				map((response: any) => {
					var cookieObj = response;
					if (response && response.token) {
						cookieObj['user']['User_Code'] = cookieObj['user']['User_Status'];
						delete cookieObj['user']['User_Status'];
						// create a cookies for newuser
						this.cookies.put('pippinTitleUsr', JSON.stringify(cookieObj));
						this.isMultiCliLogin();
						this.removeOrg();
					}
					return cookieObj;
				}),
				catchError(error => observableThrowError(error.error)),
			);
	}

	loadEnvData = async () => {
		const [err, envData] = await to(this.getEnvData());
		if (!err) {
			this.cookies.put('panelData', JSON.stringify(envData));
		}
	};

	logout(): void {
		// this.disableIntercom()
		this.setMultiAccountStatus(false);
		this.stateService.resetAll();
		this.scrollService.resetAll();
		this.endSession();
		this.cookies.remove('pippinTitleUsr');
		this.socket.close();
		this.closeSnackbar();
		if (window.HubSpotConversations) {
			window.HubSpotConversations.clear({ resetWidget: true });
			window.HubSpotConversations.widget.refresh();
			window.HubSpotConversations.widget.load();
		}
	}

	disableIntercom() {
		(<any>window).Intercom('shutdown');
		var element = document.getElementById('intercomScript');
		element.parentNode.removeChild(element);
	}

	//
	// ───────────────────────────────────────────────────────────────────── USER ─────
	//

	forget(modelObj: any) {
		let httpOptions = {
			observe: 'response' as 'body',
		};
		modelObj.access_token = this.config.getAccessToken();
		modelObj.User_Role = 'client';
		return this.http
			.put(
				this.config.getBasePath() + '/users/forgetpassword/user',
				modelObj,
				httpOptions,
			)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError((error: any) => observableThrowError(error.error)),
			);
	}
	setEmailIdForForgetPwd(forgetEmailId) {
		this.forgetEmailId = forgetEmailId;
	}
	resetEmailIdForForgetPwd() {
		this.forgetEmailId = '';
	}

	getEmailIdForForgetPwd() {
		return this.forgetEmailId;
	}

	public get fullName(): string {
		return isNotEmptyString(this.firstName) && isNotEmptyString(this.lastName)
			? `${this.firstName} ${this.lastName}`
			: null;
	}
	getName(): string {
		var curUser = this.cookies.getObject('pippinTitleUsr')['user'];
		if (curUser) return curUser['User_First_Name'] + ' ' + curUser['User_Last_Name'];
		return '';
	}

	public get firstName(): string {
		return capitalize(get(this.user, 'User_First_Name'));
	}
	getFirstName(): string {
		if (this.cookies.getObject('pippinTitleUsr'))
			return this.cookies.getObject('pippinTitleUsr')['user']['User_First_Name'];
		return '';
	}

	getOrgName(): string {
		if (this.cookies.getObject('pippinTitleUsr'))
			return this.cookies.getObject('pippinTitleUsr')['user']['Org_Name'];
		return '';
	}

	public get companyName(): string {
		return get(this.user, 'User_Company_Name');
	}
	getCompanyName(): string {
		if (this.cookies.getObject('pippinTitleUsr'))
			return this.cookies.getObject('pippinTitleUsr')['user']['User_Company_Name'];
		return '';
	}

	public get userCode(): string {
		return get(this.user, 'User_Code');
	}
	getUserCode(): string {
		if (this.cookies.getObject('pippinTitleUsr'))
			return this.cookies.getObject('pippinTitleUsr')['user']['User_Code'];
		return '';
	}

	public get lastName(): string {
		return capitalize(get(this.user, 'User_Last_Name'));
	}
	getLastName(): string {
		if (this.cookies.getObject('pippinTitleUsr'))
			return this.cookies.getObject('pippinTitleUsr')['user']['User_Last_Name'];
		return '';
	}

	public get user() {
		return get(this.cookies.getObject('pippinTitleUsr'), 'user');
	}

	public get userId(): string {
		return get(this.user, 'User_ID');
	}
	getUserId(): string {
		if (
			this.cookies.getObject('pippinTitleUsr') &&
			this.cookies.getObject('pippinTitleUsr')['user'] &&
			this.cookies.getObject('pippinTitleUsr')['user']['User_ID']
		)
			return this.cookies.getObject('pippinTitleUsr')['user']['User_ID'];
		else {
			this.logout();
			// this.router.navigate(['/login']);
			return '';
		}
	}

	isMultiCliLogin(): boolean {
		if (this.cookies.getObject('pippinTitleUsr')) {
			if (this.cookies.getObject('pippinTitleUsr')['isMultiCliLogin']) {
				this.setMultiAccountStatus(true);
				this._isMultiCompanyUser$.next(true);
				return this.cookies.getObject('pippinTitleUsr')['isMultiCliLogin'];
			} else {
				this.setMultiAccountStatus(false);
				this._isMultiCompanyUser$.next(false);
				return false;
			}
		} else {
			this.setMultiAccountStatus(false);
			this._isMultiCompanyUser$.next(false);
			return false;
		}
	}

	isOrgSelected() {
		var cookieObj = JSON.parse(this.cookies.get('pippinTitleUsr'));
		if (cookieObj['user']['Organization_ID'] && this.isMultiCompany) {
			this.setMultiAccountStatus(false);
		}
	}

	removeOrg() {
		if (this.cookies.getObject('pippinTitleUsr')['isMultiCliLogin']) {
			let cookieObj = JSON.parse(this.cookies.get('pippinTitleUsr'));
			cookieObj['user']['Organization_ID'] = null;
			this.cookies.put('pippinTitleUsr', JSON.stringify(cookieObj));
		}
	}

	public get orgId(): string {
		return get(this.user, 'Organization_ID');
	}
	getUserOrgId(): string {
		if (this.cookies.getObject('pippinTitleUsr'))
			return this.cookies.getObject('pippinTitleUsr')['user']['Organization_ID'];
		return '';
	}

	public get userRole(): string {
		return get(this.user, 'User_Role');
	}
	getUserRole(): string {
		if (this.cookies.getObject('pippinTitleUsr'))
			return this.cookies.getObject('pippinTitleUsr')['user']['User_Role'];
		return '';
	}

	getLoginHistoryId(): string {
		if (this.cookies.getObject('pippinTitleUsr'))
			return this.cookies.getObject('pippinTitleUsr')['user']['Login_History_ID'];
		return '';
	}

	isIndividualUser(): boolean {
		if (this.cookies.getObject('pippinTitleUsr'))
			return (
				this.cookies.getObject('pippinTitleUsr')['user']['User_Type'] ==
				CONSTANTS.userType.individual
			);
		return true;
	}

	getMenu(): boolean {
		return this.viewMenu;
	}

	showMenu(event) {
		this.viewMenu = !this.viewMenu;
		event.stopPropagation();
	}

	hideMenu() {
		this.viewMenu = false;
	}

	/**
	 *  ######################################End  Helper functions###################################################
	 *
	 */
	getVersionDetails() {
		//
		if (this.config.isProductionEnv()) return '';
		return this.versionData;
	}
	setVersionDetails(vdata) {
		this.versionData = vdata;
		//
	}

	endSession() {
		if (this.getToken()) {
			let httpOptions = {
				headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
			};
			let url = this.config.getBasePath() + '/auth/logout';
			this.http
				.post(url, { token: this.getToken(), User_ID: this.getUserId() }, httpOptions)
				.subscribe(
					data => {},
					err => {},
				);
		}
	}

	checkIfQualaiUser(userId) {
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
		};
		let url =
			this.config.getBasePath() + '/users/client/' + userId + '/checkIfQualiaUser';
		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}

	getOrgDetails(orgId) {
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
		};
		let url = this.config.getBasePath() + '/organizations/client/details/' + orgId;
		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}

	getEnvData() {
		let httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + this.config.getAccessToken(),
			}),
		};
		let url = this.config.getBasePath() + '/auth/client/env-variables';
		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((error: any) => observableThrowError(error.error)),
		);
	}

	closeSnackbar() {
		this._snackBar.dismiss();
	}

	init(): Subject<any> {
		const se: any = document.createElement('script');
		const responseSubj = new Subject();
		se.type = 'text/javascript';
		se.async = true;
		se.defer = true;
		se.id = 'hs-script-loader';
		se.src = this.config.getHubSpotTrackingCode();
		se.onload = se['onreadystatechange'] = function (ready) {
			responseSubj.next(true);
		};
		const _hsq = (window['_hsq'] = window['_hsq'] || []);
		_hsq.push(['setContentType', 'CONTENT_TYPE']);
		const s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(se, s);
		return responseSubj;
	}

	removeCookies() {
		this.cookies.remove('hubspotutk');
		this.cookies.remove('__hstc');
		this.cookies.remove('__hssrc');
		this.cookies.remove('__hssc');
	}

	showLoggedInProfile() {
		this.removeCookies();
		this.getProfile()
			.pipe(
				switchMap(res =>
					this.init().pipe(
						map(val => {
							this.setCookieCompanyName(res.Company_Name);
							this.setProfileDetails(res.Email_Address, res.Hubspot_Visitor_Token);
							if (this.emailAddress && this.hubSpotToken) this.getHubSpotTokenDetails();
						}),
					),
				),
			)
			.subscribe(() => {
				this.loadHubSpotChatToken();
			});
	}

	setProfileDetails(emailAddress, hubSpotToken) {
		this.emailAddress = emailAddress;
		this.hubSpotToken = hubSpotToken;
	}

	getHubSpotTokenDetails() {
		if (this.emailAddress && this.hubSpotToken) {
			window['hsConversationsSettings'] = {
				email: this.emailAddress,
				identificationEmail: this.emailAddress,
				identificationToken: this.hubSpotToken,
			};
		} else {
			console.log('Failed to load hubspot identification Token.');
			this.showLoggedInProfile();
		}
	}

	loadHubSpotChatToken() {
		if (window.HubSpotConversations) {
			window['HubSpotConversations'].widget.load();
			window['HubSpotConversations'].widget.refresh();
			//   window['HubSpotConversations'].clear({resetWidget: true});
		}
	}

	serverHealthCheck(): any {
		// let headers = new Headers({ 'Authorization': 'Bearer ' + this.getToken() });
		// let options = new RequestOptions({ headers: headers });
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.getToken() }),
			observe: 'response' as 'body',
		};
		return this.http
			.get(this.config.getBasePath() + '/users/checkstat', httpOptions)
			.pipe(
				map(
					response => response,
					error => observableThrowError(error.error),
				),
			);
	}
}
