import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StaticTextService, AboutService ,AuthService, ConfigService} from '../../services';
import { Router ,ActivatedRoute} from '@angular/router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule,FormsModule,FormGroup,FormControl,Validators,FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsComponent implements OnInit {
  //innerText:String = ''
  eula:any={}
  sanitizedEulaText: SafeHtml;
  isAccept:boolean=false;
  errorMsg:any={};
  timer:any;
  constructor(
    public text:StaticTextService,
    private route:ActivatedRoute,
    private router:Router,
    private aboutService: AboutService,
    private auth:AuthService,
    private config:ConfigService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
  ) { }

  ngOnInit() {
    // this.pages.getWithToken('terms').subscribe((res)=>{
    //   this.innerText = res.Page_Text;
    // });
    this.titleService.setTitle(`Pippin Title™ - Client - Terms and Conditions`)
    if(this.route.snapshot.params['userId']){
      this.isAccept=true;         
      this.aboutService.getActiveEULA()
      .subscribe((data)=>{
        if(data){
          this.eula=data;
          this.sanitizedEulaText = this.sanitizer.bypassSecurityTrustHtml(this.eula.EULA_Text);
        }
      })
    }else{
      this.aboutService.getEULAByUserId(this.auth.getUserId())
      .subscribe((data)=>{
        if(data){
          this.eula=data;
          this.sanitizedEulaText = this.sanitizer.bypassSecurityTrustHtml(this.eula.EULA_Text);
        }
      })
    }
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  ngOnDestroy(){
    if(this.timer)clearTimeout(this.timer)
  }

  updateUserEULA(){
    var data={
      EULA_Version:this.eula.EULA_Version
    }
    this.aboutService.updateUserEULA(this.route.snapshot.params['userId'],data)
    .subscribe((response)=>{
      console.log({response})
      let obj = {};
      this.auth.showLoggedInProfile();
      if(this.auth.getUserId()) {
        obj['User_ID'] = this.auth.getUserId();
      } else { 
        obj['User_ID'] = this.route.snapshot.params['userId'];
      }
      obj['NPS_Rating_Category'] = 0;       // portal - 0; Email - 1     
      obj['NPS_Rating_Acceptance'] = 0;     //Acceptanced - 1, Decliend - 0
      obj['NPS_Rating_Value'] = null;
      if(!response.isApproved){
        this.router.navigate(['pendingApproval/' + obj['User_ID']]).then();
        return;
      }
      this.aboutService.updateUserRating(obj)
        .subscribe((data) => {
          this.router.navigate(['orders/current']);
        }, (err) => {
          console.log("Error while updating the user's NPS data", err);
          this.router.navigate(['orders/current']);          
        })      
    },(err)=>{
      
      this.errorMsg=err;
    })
  }

 }
