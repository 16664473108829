// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  basePath: 'https://serverdev.pippintitle.com',
	clientHome: 'https://appdev.pippintitle.com',
  access_token: 'xWeh4H0QgqIfM4k5cGW7XQQdIxLXxf9k',
  encryption_key:'b212d032-75e4-4093-bcdf-720795fd549b',
  aws:{
    apiVersion:'2006-03-01',
    region:'us-west-2',
    bucket:'dev.fs.pippintitle',
    credentials:'us-west-2:1a57f7a1-ea10-4f25-86a8-295c8464d9fd'
  },
  intercom:{
    app_id:"rn4117js",
  },
  googleCaptcha:{
    siteKey:"6Ldl0KckAAAAADHlbOci2tSuoCpMd7r6hTD0otpZ"
		// siteKey:"6LdqmvgZAAAAAIjIC4o-Imiz_0Q0sJISU6Xc8x0f"

  },
  stripePublishKey: 'pk_test_5hYo6eFVfBSkOpW97r2DGRTF00jCpNqUIZ', // Kelment Publishable key
  hubSpotTrackingCode:'https://js-na1.hs-scripts.com/20461754.js',
	nameSearchProductId:1038
};
